import { getFile } from '@utils/function'
import displayFile from '@utils/display-file'

const downloadFile = async (url, fileName, fileType) => {
  const fileObject = await getFile(url, fileName, fileType)
  if (!fileObject) {
    return false
  }
  displayFile(fileObject, fileName, fileType)
  return true
}

export default downloadFile
