<template>
  <v-card flat :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('menu.balanceSheet') }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <app-input
        class="mx-2"
        name="lastYearTrialBalanceId"
        type="select-server"
        :label="$t('fields.lastYearTrialBalance')"
        :view="false"
        :binds="{
          apiUrl: `trial-balance/trial-balance-header/?contact_id=${contactId}&state=approved`
        }"
        v-model="printBalanceSheet.lastYearTrialBalanceId"
        :disabled="loading"
      />
      <app-input
        class="mx-2"
        name="lastYearAuditId"
        type="select-server"
        :label="$t('fields.lastYearAudit')"
        :view="false"
        :binds="{
          apiUrl: `audit/audit-header/?contact_id=${contactId}&state=approved`
        }"
        v-model="printBalanceSheet.lastYearAuditId"
        :disabled="loading"
      />
      <v-spacer></v-spacer>
      <v-btn
        class="mx-1"
        color="primary"
        @click="onGenerateClicked"
        :disabled="loading"
      >
        {{ $t('btn.generateReport') }}
      </v-btn>
      <v-btn
        class="mx-1"
        color="primary"
        @click="() => onPrintBalanceSheetClicked()"
        :disabled="loading"
      >
        {{ $t('btn.printBalanceSheet') }}
      </v-btn>
      <v-btn
        class="mx-1 px-0"
        @click="() => onPrintBalanceSheetClicked(true)"
        :disabled="loading"
      >
        <v-icon dense>mdi-download</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title class="pt-0">
      <v-row>
        <v-col
          ><app-input
            name="currentYearPreferredStock"
            :rules="{
              required:
                !isPublicCompany &&
                !isLimitedPartnership &&
                !isForeign &&
                !isJointVenture
            }"
            :label="$t('fields.currentYearPreferredStock')"
            :view="false"
            :disabled="
              isPublicCompany ||
              isLimitedPartnership ||
              isForeign ||
              isJointVenture ||
              loading
            "
            v-model="printBalanceSheet.currentYearPreferredStock"
        /></v-col>
        <v-col>
          <app-input
            name="lastYearPreferredStock"
            :rules="{
              required:
                !isPublicCompany &&
                !isLimitedPartnership &&
                !isForeign &&
                !isJointVenture
            }"
            :label="$t('fields.lastYearPreferredStock')"
            :view="false"
            :disabled="
              isPublicCompany ||
              isLimitedPartnership ||
              isForeign ||
              isJointVenture ||
              loading
            "
            v-model="printBalanceSheet.lastYearPreferredStock"
          />
        </v-col>
        <v-col>
          <app-input
            name="currentYearCommonStock"
            :rules="{
              required:
                !isPublicCompany &&
                !isLimitedPartnership &&
                !isForeign &&
                !isJointVenture
            }"
            :label="$t('fields.currentYearCommonStock')"
            :view="false"
            :disabled="
              isPublicCompany ||
              isLimitedPartnership ||
              isForeign ||
              isJointVenture ||
              loading
            "
            v-model="printBalanceSheet.currentYearCommonStock"
          />
        </v-col>
        <v-col
          ><app-input
            name="lastYearCommonStock"
            :rules="{
              required:
                !isPublicCompany &&
                !isLimitedPartnership &&
                !isForeign &&
                !isJointVenture
            }"
            :label="$t('fields.lastYearCommonStock')"
            :view="false"
            :disabled="
              isPublicCompany ||
              isLimitedPartnership ||
              isForeign ||
              isJointVenture ||
              loading
            "
            v-model="printBalanceSheet.lastYearCommonStock"
        /></v-col>
      </v-row>
    </v-card-title>
    <v-card-title>
      <v-text-field
        v-if="responseData"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <app-report-table
        v-if="responseData"
        :server-side="false"
        app="audit"
        model="audit"
        hide-default-footer
        :loading="loading"
        :headers="headers"
        :client-items="tableItems"
        :search="search"
        :group-by="'group1'"
        item-key="key"
      >
      </app-report-table
    ></v-card-text>
  </v-card>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppReportTable from '@components/AppReportTable.vue'
import _ from 'lodash'
import displayFile from '@utils/display-file'

export default {
  name: 'app-control-pl',
  components: {
    AppReportTable,
    AppInput
  },
  props: {
    loading: Boolean,
    auditHeaderId: [String, Number],
    contactId: Number,
    isPublicCompany: {
      type: Boolean,
      default: false
    },
    isLimitedPartnership: {
      type: Boolean,
      default: false
    },
    isForeign: {
      type: Boolean,
      default: false
    },
    isJointVenture: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:loading'],
  data() {
    return {
      printBalanceSheet: {},
      responseData: null,
      tableItems: [],
      search: ''
    }
  },
  computed: {
    perBookDate() {
      return this.responseData != null ? this.responseData['per_book_date'] : ''
    },
    perAuditDate() {
      return this.responseData != null
        ? this.responseData['per_audit_date']
        : ''
    },
    lastYearPerAuditDate() {
      return this.responseData != null
        ? this.responseData['last_year_per_audit_date']
        : ''
    },
    headers() {
      return [
        {
          text: this.$t('fields.accountName'),
          value: 'report_name',
          // value: 'audit_account_type_name',
          sortable: false
        },
        {
          text: this.$t('fields.ref'),
          value: 'task_names',
          sortable: false
        },
        {
          html: this.newLineIfValueNotEmpty(
            this.$t('fields.perBookBalance'),
            this.perBookDate
          ),
          value: 'per_book_balance',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'adj_refs',
          sortable: false
        },
        {
          text: this.$t('fields.adjDr'),
          value: 'adj_debit',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          text: this.$t('fields.adjCr'),
          value: 'adj_credit',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          html: this.newLineIfValueNotEmpty(
            this.$t('fields.perAuditBalance'),
            this.perAuditDate
          ),
          value: 'per_audit_balance',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          html: this.newLineIfValueNotEmpty(
            this.$t('fields.perAuditBalance'),
            this.lastYearPerAuditDate
          ),
          value: 'last_year_per_audit_balance',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          text: this.$t('fields.amountChange'),
          value: 'per_audit_balance_change_amount',
          accountFormat: true,
          align: 'right',
          sortable: false
        },
        {
          text: this.$t('fields.percentageChange'),
          value: 'per_audit_balance_change_percent',
          percentFormat: true,
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  methods: {
    newLineIfValueNotEmpty(text, value) {
      if (value) {
        return `${text}<br/>${value}`
      }
      return text
    },
    async onGenerateClicked() {
      this.$emit('update:loading', true)
      try {
        const response = await this.$api({
          url: `audit/generate-balance-sheet/${this.auditHeaderId}/`,
          hideSuccessAlert: true,
          params: {
            current_year_preferred_stock:
              this.printBalanceSheet.currentYearPreferredStock,
            current_year_common_stock:
              this.printBalanceSheet.currentYearCommonStock,
            last_year_preferred_stock:
              this.printBalanceSheet.lastYearPreferredStock,
            last_year_common_stock: this.printBalanceSheet.lastYearCommonStock,
            last_year_audit_id: this.printBalanceSheet.lastYearAuditId?.id,
            last_year_trial_balance_id:
              this.printBalanceSheet.lastYearTrialBalanceId?.id
          }
        })
        this.responseData = response.data
        if (response.data && response.data['items']) {
          this.tableItems = _.values(response.data['items'])
        } else {
          this.tableItems = []
        }
      } finally {
        this.$emit('update:loading', false)
      }
    },
    async onPrintBalanceSheetClicked(download = false) {
      this.$emit('update:loading', true)
      try {
        const response = await this.$api({
          url: `audit/report-balance-sheet/${this.auditHeaderId}/`,
          hideSuccessAlert: true,
          hideErrorAlert: true,
          responseType: 'blob',
          params: {
            current_year_preferred_stock:
              this.printBalanceSheet.currentYearPreferredStock,
            current_year_common_stock:
              this.printBalanceSheet.currentYearCommonStock,
            last_year_preferred_stock:
              this.printBalanceSheet.lastYearPreferredStock,
            last_year_common_stock: this.printBalanceSheet.lastYearCommonStock,
            last_year_audit_id: this.printBalanceSheet.lastYearAuditId?.id,
            last_year_trial_balance_id:
              this.printBalanceSheet.lastYearTrialBalanceId?.id
          }
        })
        const fileName = download
          ? response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1]
            : 'print_balance_sheet.pdf'
          : null
        const fileType = response.headers['content-type']
        displayFile(response.data, fileName, fileType)
      } catch (e) {
        console.error(e)
        const responseText = await e.data.text()
        if (responseText) {
          const response = JSON.parse(responseText)
          this.$alert('custom', {
            icon: 'error',
            title: this.$t('alert.validationError'),
            text: response['message']
          })
        }
      } finally {
        this.$emit('update:loading', false)
      }
    }
  }
}
</script>
