<template>
  <v-card>
    <v-card-text v-if="view && !existingFiles.length">
      <span>No data</span>
    </v-card-text>
    <v-card-text>
      <v-row v-if="!view">
        <v-col cols="10" lg="8">
          <app-input
            name="documents"
            type="file"
            :label="label ?? $t('fields.document')"
            :view="view"
            :binds="{ multiple: true, chips: true }"
            v-model="files"
          />
        </v-col>
        <v-col cols="2">
          <v-btn v-if="!view" @click="onSave" color="primary">
            {{ $t('btn.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-list>
            <v-list-item v-for="(file, index) in existingFiles" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon>mdi-file</v-icon>
                  {{ file.file_name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click.prevent="() => download(file)">
                  <v-icon color="green lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="!view">
                <v-btn icon @click.prevent="() => deleteClick(file)">
                  <v-icon color="grey lighten-1">mdi-trash-can</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import AppInput from '@components/AppInput.vue'
import { ref } from 'vue'
import request from '@utils/request'
import { objFormData } from '@utils/function'
import downloadFile from '@utils/download-file'
import swal, { swalError } from '@utils/swal'
import { useI18n } from 'vue-i18n-bridge'

const props = defineProps({
  loading: Boolean,
  view: Boolean,
  mode: String,
  label: String,
  getUrl: String,
  saveUrl: String
})
const emit = defineEmits(['update:loading'])

const { t } = useI18n()

const files = ref([])
const existingFiles = ref([])
const documentReady = ref(false)

const refresh = () => {
  documentReady.value = false
  if (props.getUrl) {
    emit('update:loading', true)
    request
      .get(props.getUrl)
      .then((response) => {
        existingFiles.value = response.data.results
        documentReady.value = true
      })
      .finally(() => {
        emit('update:loading', false)
      })
  } else {
    documentReady.value = true
  }
}
refresh()
const onSave = async () => {
  emit('update:loading', true)
  await request
    .post(props.saveUrl, objFormData({ documents: files.value }), {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(() => {
      documentReady.value = true
      files.value.splice(0, files.value.length)
    })
    .finally(() => {
      emit('update:loading', false)
    })
  refresh()
}
const download = async (file) => {
  const result = await downloadFile(file.file, file.file_name, file.file_type)
  if (!result) {
    swalError(t, 'Error downloading file')
  }
}

const deleteClick = (file) => {
  swal
    .fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    })
    .then((result) => {
      if (result.isConfirmed) {
        emit('update:loading', true)
        request
          .delete(`${props.saveUrl}`, {
            data: {
              pks: [file.id]
            }
          })
          .then(() => {
            refresh()
          })
          .finally(() => {
            emit('update:loading', false)
          })
      }
    })
}
</script>

<style scoped lang="scss"></style>
