// Take a blob object and either download it if fileName is provided or open it in a new tab if not
const displayFile = (fileObject, fileName, fileType) => {
  let href, link
  try {
    href = window.URL.createObjectURL(
      new Blob([fileObject], {
        type: fileType,
        encoding: 'UTF-8'
      })
    )
    link = document.createElement('a')
    link.href = href
    if (fileName) {
      link.download = fileName
    }
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    console.error(e)
  } finally {
    if (link) {
      document.body.removeChild(link)
    }
    if (href) {
      setTimeout(function () {
        window.URL.revokeObjectURL(href)
      }, 40000)
    }
  }
}

export default displayFile
