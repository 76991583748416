var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('menu.balanceSheet')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('app-input',{staticClass:"mx-2",attrs:{"name":"lastYearTrialBalanceId","type":"select-server","label":_vm.$t('fields.lastYearTrialBalance'),"view":false,"binds":{
        apiUrl: `trial-balance/trial-balance-header/?contact_id=${_vm.contactId}&state=approved`
      },"disabled":_vm.loading},model:{value:(_vm.printBalanceSheet.lastYearTrialBalanceId),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "lastYearTrialBalanceId", $$v)},expression:"printBalanceSheet.lastYearTrialBalanceId"}}),_c('app-input',{staticClass:"mx-2",attrs:{"name":"lastYearAuditId","type":"select-server","label":_vm.$t('fields.lastYearAudit'),"view":false,"binds":{
        apiUrl: `audit/audit-header/?contact_id=${_vm.contactId}&state=approved`
      },"disabled":_vm.loading},model:{value:(_vm.printBalanceSheet.lastYearAuditId),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "lastYearAuditId", $$v)},expression:"printBalanceSheet.lastYearAuditId"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.onGenerateClicked}},[_vm._v(" "+_vm._s(_vm.$t('btn.generateReport'))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":() => _vm.onPrintBalanceSheetClicked()}},[_vm._v(" "+_vm._s(_vm.$t('btn.printBalanceSheet'))+" ")]),_c('v-btn',{staticClass:"mx-1 px-0",attrs:{"disabled":_vm.loading},on:{"click":() => _vm.onPrintBalanceSheetClicked(true)}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-download")])],1)],1),_c('v-card-title',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',[_c('app-input',{attrs:{"name":"currentYearPreferredStock","rules":{
            required:
              !_vm.isPublicCompany &&
              !_vm.isLimitedPartnership &&
              !_vm.isForeign &&
              !_vm.isJointVenture
          },"label":_vm.$t('fields.currentYearPreferredStock'),"view":false,"disabled":_vm.isPublicCompany ||
            _vm.isLimitedPartnership ||
            _vm.isForeign ||
            _vm.isJointVenture ||
            _vm.loading},model:{value:(_vm.printBalanceSheet.currentYearPreferredStock),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "currentYearPreferredStock", $$v)},expression:"printBalanceSheet.currentYearPreferredStock"}})],1),_c('v-col',[_c('app-input',{attrs:{"name":"lastYearPreferredStock","rules":{
            required:
              !_vm.isPublicCompany &&
              !_vm.isLimitedPartnership &&
              !_vm.isForeign &&
              !_vm.isJointVenture
          },"label":_vm.$t('fields.lastYearPreferredStock'),"view":false,"disabled":_vm.isPublicCompany ||
            _vm.isLimitedPartnership ||
            _vm.isForeign ||
            _vm.isJointVenture ||
            _vm.loading},model:{value:(_vm.printBalanceSheet.lastYearPreferredStock),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "lastYearPreferredStock", $$v)},expression:"printBalanceSheet.lastYearPreferredStock"}})],1),_c('v-col',[_c('app-input',{attrs:{"name":"currentYearCommonStock","rules":{
            required:
              !_vm.isPublicCompany &&
              !_vm.isLimitedPartnership &&
              !_vm.isForeign &&
              !_vm.isJointVenture
          },"label":_vm.$t('fields.currentYearCommonStock'),"view":false,"disabled":_vm.isPublicCompany ||
            _vm.isLimitedPartnership ||
            _vm.isForeign ||
            _vm.isJointVenture ||
            _vm.loading},model:{value:(_vm.printBalanceSheet.currentYearCommonStock),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "currentYearCommonStock", $$v)},expression:"printBalanceSheet.currentYearCommonStock"}})],1),_c('v-col',[_c('app-input',{attrs:{"name":"lastYearCommonStock","rules":{
            required:
              !_vm.isPublicCompany &&
              !_vm.isLimitedPartnership &&
              !_vm.isForeign &&
              !_vm.isJointVenture
          },"label":_vm.$t('fields.lastYearCommonStock'),"view":false,"disabled":_vm.isPublicCompany ||
            _vm.isLimitedPartnership ||
            _vm.isForeign ||
            _vm.isJointVenture ||
            _vm.loading},model:{value:(_vm.printBalanceSheet.lastYearCommonStock),callback:function ($$v) {_vm.$set(_vm.printBalanceSheet, "lastYearCommonStock", $$v)},expression:"printBalanceSheet.lastYearCommonStock"}})],1)],1)],1),_c('v-card-title',[(_vm.responseData)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-card-text',[(_vm.responseData)?_c('app-report-table',{attrs:{"server-side":false,"app":"audit","model":"audit","hide-default-footer":"","loading":_vm.loading,"headers":_vm.headers,"client-items":_vm.tableItems,"search":_vm.search,"group-by":'group1',"item-key":"key"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }